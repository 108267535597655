// I18n
export const defaultLocale = 'en';
export const defaultDateFormat = 'YYYY-MM-DD';
export const defaultDateTimeFormat = defaultDateFormat + '  HH:mm:ss';

// Pages
export const PageList = [
  { name: 'products', href: '/products' },
  { name: 'orders', href: '/orders' },
  { name: 'profile', href: '/profile' },
  { name: 'contact', href: '/contact' },
];
export const InfoPageList = [
  { name: 'aszf', href: '/pdf/CWH_angol_aszf.pdf', target: '_blank' },
  { name: 'cookie', href: '/cookie' },
  { name: 'privacy', href: '/privacy-policy' },
  // { name: 'ship_pay', href: '/shipping-and-payment' },
];
export const DesktopPageList = [
  { name: 'products', href: '/products' },
  { name: 'contact', href: '/contact' },
];
export const protectedRoutes = [
  ...PageList.map(({ href }) => href), //
  '/cart',
];

// Auth
export const AuthTokenName = 'session_token.next-auth';
export const AuthTokenExpire = 60 * 60 * 24; // 1 day
export const AuthTokenMaxAge = 1000 * AuthTokenExpire; // 1 day
