import {
  isServer,
  QueryClient,
  QueryClientConfig,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { merge } from 'lodash';

import { API_Actions } from '@/libs/api.actions';

/**
 * Query Client
 */
let browserQueryClient: QueryClient | undefined = undefined;
function makeQueryClient(config?: QueryClientConfig) {
  return new QueryClient(
    merge(
      {
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
            refetchInterval: 30 * 1000,
            refetchOnWindowFocus: true,
            refetchIntervalInBackground: true,
          },
          mutations: {
            //TODO: make it global wihtout crashing the ssr, i mean use toast or logs dynamically depends on where we call it
            // onError: (error) => {
            //   console.error('API error:', error);
            // },
          },
          // dehydrate: {
          //   shouldDehydrateQuery: (query) =>
          //     defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
          // },
        },
      } satisfies QueryClientConfig,
      config
    )
  );
}
export function getQueryClient(config?: QueryClientConfig) {
  if (isServer) return makeQueryClient(config);
  else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient(config);
    return browserQueryClient;
  }
}
export const queryClient = getQueryClient();

/**
 * Types: Query
 */
type QueryParamsType = {
  'auth.profile': never;
  //contacts
  'contacts.list': never;
  //products
  'products.list': never;
  'products.get': number;
  //category
  'categories.list': never;
  //orders
  'orders.list': never;
  'orders.get': number;
  //calendar
  'calendar.delivery-dates': never;
  'calendar.pick-up-dates': never;
};
export type API_QueryPaths = keyof QueryParamsType;
export type GetQueryType<P extends API_QueryPaths> = Awaited<
  ReturnType<(typeof API_QueryMap)[P]['queryFn']>
>;
export type DynamicParamsType<P extends API_QueryPaths> =
  QueryParamsType[P] extends undefined | null | never //prettier-ignore
    ? { params?: never }
    : { params: QueryParamsType[P] };

/**
 * Types: Mutation
 */
export type API_MutationPaths =
  | 'auth.login'
  | 'auth.registration'
  | 'auth.forgot-password'
  | 'auth.new-password'
  | 'auth.change-password'
  | 'auth.profile-update'
  | 'auth.profile-delete'
  | 'auth.contact-email'
  | 'orders.create';

export type GetMutationType<
  P extends API_MutationPaths,
  Prop extends 'ReturnType' | 'Variables' = 'ReturnType',
> = Prop extends 'ReturnType'
  ? Awaited<ReturnType<(typeof API_MutationMap)[P]['mutationFn']>>
  : Parameters<(typeof API_MutationMap)[P]['mutationFn']>[number] extends never
    ? void
    : Parameters<(typeof API_MutationMap)[P]['mutationFn']>[number];

/**
 * Constants: Query
 */
export const API_QueryMap = {
  // Auth
  'auth.profile': {
    queryKey: ['auth', 'profile'],
    queryFn: API_Actions.auth.profile,
  },
  // Contacts
  'contacts.list': {
    queryKey: ['contacts', 'list'],
    queryFn: API_Actions.contacts.list,
  },
  // Products
  'products.list': {
    queryKey: ['products', 'list'],
    queryFn: API_Actions.products.list,
  },
  'products.get': {
    queryKey: ['products', 'get'],
    queryFn: ({ meta }) => API_Actions.products.get(meta?.params ?? 0),
  },
  // Category
  'categories.list': {
    queryKey: ['category', 'list'],
    queryFn: API_Actions.categories.list,
  },
  // Orders
  'orders.list': {
    queryKey: ['orders', 'list'],
    queryFn: API_Actions.orders.list,
  },
  'orders.get': {
    queryKey: ['orders', 'get'],
    queryFn: ({ meta }) => API_Actions.orders.get(meta?.params ?? 0),
  },
  // Calendar
  'calendar.delivery-dates': {
    queryKey: ['calendar', 'delivery-dates'],
    queryFn: API_Actions.calendar['delivery-dates'],
  },
  'calendar.pick-up-dates': {
    queryKey: ['calendar', 'pick-up-dates'],
    queryFn: API_Actions.calendar['pick-up-dates'],
  },
} satisfies Record<API_QueryPaths, UseQueryOptions>;

/**
 * Constants: Mutation
 */
export const API_MutationMap = {
  // Auth
  'auth.login': {
    mutationKey: ['auth', 'login'],
    mutationFn: API_Actions.auth.login,
  },
  'auth.registration': {
    mutationKey: ['auth', 'registration'],
    mutationFn: API_Actions.auth.registration,
  },
  'auth.forgot-password': {
    mutationKey: ['auth', 'forgot-password'],
    mutationFn: API_Actions.auth['forgot-password'],
  },
  'auth.new-password': {
    mutationKey: ['auth', 'new-password'],
    mutationFn: API_Actions.auth['new-password'],
  },
  'auth.change-password': {
    mutationKey: ['auth', 'change-password'],
    mutationFn: API_Actions.auth['change-password'],
  },
  'auth.profile-update': {
    mutationKey: ['auth', 'profile-update'],
    mutationFn: API_Actions.auth['profile-update'],
  },
  'auth.profile-delete': {
    mutationKey: ['auth', 'profile-delete'],
    mutationFn: API_Actions.auth['profile-delete'],
  },
  'auth.contact-email': {
    mutationKey: ['auth', 'contact-email'],
    mutationFn: API_Actions.auth['contact-email'],
  },
  // Orders
  'orders.create': {
    mutationKey: ['orders', 'create'],
    mutationFn: API_Actions.orders.create,
  },
} satisfies Record<API_MutationPaths, UseMutationOptions<any, Error, any>>;
