import type { FormValues } from '@/app/(main)/contact/_components/ContactForm';
import { APIList_Response } from '@/types/api';
import { ContactPersonType } from '@/types/api/contact-persons.type';
import { DeliveryDataType } from '@/types/api/delivery.type';
import { OrderCreateDataType, OrderType } from '@/types/api/orders.type';
import { ProductCategoryType } from '@/types/api/product-categories.type';
import { ProductListResponse, ProductType } from '@/types/api/products.type';
import {
  UserLoginDataType,
  UserLoginResponse,
  UserRegistrationDataType,
  UserType,
  UserUpdateDataType,
} from '@/types/api/users.type';
import axios from '@/libs/axios';

/**
 * Types
 */
type SimpleResponseWithMessage = { message: string };

/**
 * Processors
 */
const extractData = <T>(res: { data: T }) => res.data;

const ListFn =
  (fn: <IT>(item: any) => IT = (v) => v) =>
  <LT>(data: APIList_Response<LT> | LT[]): LT[] => {
    if ((['data', 'pagination'] as const).every((props) => !!(data as APIList_Response<LT>)[props]))
      return (data as APIList_Response<LT>).data.map<LT>(fn);
    if (Array.isArray(data)) return data.map<LT>(fn);
    return [];
  };
// const product_fn =
//   (profile: UserType, categories: ProductCategoryType[]) =>
//   <IT extends ProductType>(item: IT): IT => {
//     // if (profile) item.price = getPriceByCurrency(item, profile);
//     // if (!item.available_quantity && item.id % 4 > 0) item.available_quantity = 1;

//     // return {
//     // ...item,
//     // category: item.category || categories[getRandomInteger(0, categories.length - 1)] || null,
//     // images: ['https://www.pdhvac.com/site/assets/files/1246/usa-products.webp'],
//     // item.images === null ? ['https://www.pdhvac.com/site/assets/files/1246/usa-products.webp'] : item.images,
//     // price_eur: item.price_eur || getRandomInteger(99, 2999),
//     // price_usd: item.price_usd || getRandomInteger(99, 2999),
//     // category: item.category || categories[getRandomInteger(0, categories.length - 1)] || null,
//     // status: item.status.toUpperCase() as ProductType['status'],
//     // images: ['https://www.pdhvac.com/site/assets/files/1246/usa-products.webp', ...item.images]
//     // };

//     return item;
//   };
// const order_fn =
//   (products: ProductType[]) =>
//   <IT extends OrderType>(item: IT): IT => {
//     // item.sap_id ||= 'MA-20240307-C-' + item.id;
//     // item.order_number ||= 'MA-20240307-C-' + item.id;
//     // item.status &&= item.status.toUpperCase() as OrderType['status'];
//     // item.order_items = item.order_items.map((order_item) => ({
//     //   ...order_item,
//     //   product: products.find((p) => p.id === order_item.ProductID),
//     // }));
//     // item.created_at = dayjs().add(getRandomInteger(-2, 3), 'day').toISOString();
//     return item;
//   };

/**
 * TODO:
 * - type the errors as well and make sure to handle them in the components
 * - clear all the trash
 */
export const API_Actions = {
  auth: {
    login: (data: UserLoginDataType) =>
      axios.post<UserLoginResponse>('/api/auth/login', data).then(extractData),
    registration: (data: UserRegistrationDataType) =>
      axios.post<SimpleResponseWithMessage>('/api/auth/registration', data).then(extractData),
    profile: () => axios.get<UserType>('/api/web/user-profile').then(extractData),
    'profile-update': (data: UserUpdateDataType) =>
      axios.post('/api/web/user-profile', data).then(extractData),
    'profile-delete': () => axios.delete('/api/web/user-profile').then(extractData),
    'forgot-password': (email: string) =>
      axios.post('/api/auth/forgot-password', { email }).then(extractData),
    'new-password': ({ token, ...data }: { token: string; password: string; password2: string }) =>
      axios
        .put('/api/web/user-profile/new-password', data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(extractData),
    'change-password': (data: { old_password: string; password: string; password2: string }) =>
      axios.put('/api/web/user-profile/change-password', data).then(extractData),
    'contact-email': (data: FormValues) =>
      axios.post('/api/web/contact-email', data).then(extractData),
  },
  contacts: {
    list: () => axios.get<ContactPersonType[]>('/api/contact-persons').then(extractData),
    // .then(
    //   (items) =>
    //     //TODO: It should be happen in the backend
    //     items.map((item) => ({
    //       ...item,
    //       name: item.first_name + ' ' + item.last_name,
    //     })) satisfies ContactPersonType[]
    // ),
  },
  categories: {
    list: () => axios.get<ProductCategoryType[]>('/api/web/product-categories').then(extractData),
  },
  products: {
    list: async () => {
      // const profile = await API_Actions.auth.profile();
      // const categories = await API_Actions.categories
      //   .list()
      //   .then((data) =>
      //     data.reduce<ProductCategoryType[]>((l, v) => [...l, v, ...(v.sub_categories || [])], [])
      //   );

      return axios
        .get<ProductListResponse>('/api/web/products?size=1000') //
        .then(extractData)
        .then(ListFn(/* product_fn(profile, categories) */));
      //TODO: remove this line after backend is ready with the real data
    },
    get: async (id?: number) => {
      if (!id) throw Error("API_Actions => products => get => 'id' is required");

      // const profile = await API_Actions.auth.profile();
      // const categories = await API_Actions.categories
      //   .list()
      //   .then((data) =>
      //     data.reduce<ProductCategoryType[]>((l, v) => [...l, v, ...(v.sub_categories || [])], [])
      //   );

      return axios.get<ProductType>(`/api/web/products/${id}`).then(extractData);
      // .then(product_fn(profile, categories)); //TODO: remove this line after backend is ready with the real data
    },
  },
  orders: {
    list: async () => {
      // const products = await API_Actions.products.list();

      return axios
        .get<OrderType[]>('/api/web/orders')
        .then((data) => {
          if (data.data === null) data.data = [];
          // console.log('🚀 ➡️ .then ➡️ data.data:', data.data);
          return data;
        })
        .then(extractData);
      // .then(ListFn(/* order_fn(products) */)); //TODO: remove this line after backend is ready with the real data
    },
    get: async (id?: number) => {
      if (!id) throw Error("API_Actions => orders => get => 'id' is required");
      // const products = await API_Actions.products.list();

      return axios.get<OrderType>(`/api/web/orders/${id}`).then(extractData);
      // .then(order_fn(products)); //TODO: remove this line after backend is ready with the real data
    },
    create: async (data: OrderCreateDataType) => {
      return axios.post<SimpleResponseWithMessage>('/api/web/orders', data).then(extractData);
    },
  },
  calendar: {
    'delivery-dates': async () => {
      return axios.get<DeliveryDataType[]>('/api/web/calendar/delivery-dates').then(extractData);
    },
    'pick-up-dates': async () => {
      return axios.get<DeliveryDataType[]>('/api/web/calendar/pick-up-dates').then(extractData);
    },
  },
};
export default API_Actions;
