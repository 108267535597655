'use client';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import { getQueryClient } from '@/configs/react-query';

/**
 * Types
 */
type ReactQueryProps = {
  children: React.ReactNode;
};

/**
 * Component: ReactQuery
 */
export default function ReactQuery({
  children
}: ReactQueryProps) {
  const queryClient = getQueryClient();
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="ReactQuery" data-sentry-source-file="ReactQuery.tsx">
      <ReactQueryStreamedHydration queryClient={queryClient} data-sentry-element="ReactQueryStreamedHydration" data-sentry-source-file="ReactQuery.tsx">
        {children}
      </ReactQueryStreamedHydration>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools client={queryClient} />}
    </QueryClientProvider>;
}